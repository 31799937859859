<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search_excel"
                  class="ml-1"
                  v-if="user.user_id == 2||user.user_id == 6"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">导入</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="申请编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.inNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.poNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.product"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="支持69码以及名称"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="发票代码:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.invoiceCode"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="发票号码:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.invoiceNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="采购日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['purchaseTime'])"
                                 :params="['purchaseTime']"
                                 :value="condition.purchaseTime"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="供应商"
                    type="input"
                    v-on:change="fromChildren($event,['supplierName','supplierId'])"
                    :params="['supplierName','supplierId']"
                    :value="condition.supplierName"
                    modalName="供应商"
                    placeholder="点击选择供应商"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="开票状态"
                    label-for="invoicing_status"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('invoice_status')"
                      v-model="condition.invoicingStatus"
                      name="stocktaking_status"
                      class="select-size-sm"
                      placeholder="请选择状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="含税单价:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-input
                          v-model="condition.startPrice"
                          name="orderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                          type="number"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-input
                          v-model="condition.endPrice"
                          name="orderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                          type="number"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>


              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="showAddModal"
                >
                  <span class="align-right">录入发票</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportExcel"
                >
                  <span class="align-right">导出</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card
            v-show="advanced_search_modal_excel"
        >
          <b-form

          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="show"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:head(#)="data">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj.sync="isCheckedObj"
              :table="table"
              type="head"
              :row="data"
          />
        </template>
        <template v-slot:cell(#)="row">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj="isCheckedObj"
              :table="table"
              type="row"
              :row="row"
              :rowChecked.sync="isCheckedObj['index'+row.item.id]"
          />
        </template>

        <!-- Column: Type -->
        <template #cell(in_no)="data">
          [{{ data.item.in_id }}]{{ data.item.in_no }}
        </template>

        <template #cell(order_no)="data">
          <b-link
              :to="{ name: 'apps-purchaseorder-list', query: {order_id: data.item.order_id} }"
              target="_blank"
          >
            {{ data.item.order_no }}
          </b-link>
        </template>


        <template #cell(purchase_time)="data">
          {{ toDate(data.item.purchase_time) }}
        </template>

        <template #cell(returnqty)="data">
          {{ data.item.ext.returnQty }}
        </template>

        <template #cell(tax_rate)="data">
          {{ data.item.ext.rate }}
        </template>

        <template #cell(delivery_amount)="data">
          {{ data.item.ext.deliveryAmount }}
        </template>

        <template #cell(no_invoice_qty)="data">
          {{ data.item.ext.noInvoiceQty }}
        </template>

        <template #cell(invoice_code)="data">
          <div>
            {{  isEmpty(data.item.ext.invoiceCodes)?'':data.item.ext.invoiceCodes.substring(0, 10) }}
            <feather-icon
                v-if="!isEmpty(data.item.ext.invoiceCodes)&&data.item.ext.invoiceCodes.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.ext.invoiceCodes+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <template #cell(invoice_no)="data">
          <div class="text-primary" @click="showPurchaseInvoice(data.item)">
            {{  isEmpty(data.item.ext.invoiceNos)?'':data.item.ext.invoiceNos.substring(0, 10) }}
            <feather-icon
                v-if="!isEmpty(data.item.ext.invoiceNos)&&data.item.ext.invoiceNos.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.ext.invoiceNos+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

<!--        <template #cell(invoice_code)="data">-->
<!--          {{ data.item.ext.invoiceCodes }}-->
<!--        </template>-->

<!--        <template #cell(invoice_no)="data">-->
<!--          <div class="text-primary" @click="showPurchaseInvoice(data.item)">-->
<!--            {{ data.item.ext.invoiceNos }}-->
<!--          </div>-->

<!--        </template>-->

        <template #cell(invoicing_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('invoice_status', data.item.invoicing_status)}`"
          >
            {{ getCodeLabel("invoice_status", data.item.invoicing_status) }}
          </b-badge>
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="delPII(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="modal-remark"
        ok-title="保存"
        @ok="create"
        cancel-title="取消"
        @cancel="function() {
          reload()
        }"
        @close="function (){
          reload()
        }"
        centered
        size="xl"
        title="发票明细"
        ref="addModal"
    >
      <b-card
          no-body
          class="mb-0"
      >
        <b-row>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票代码:"
                label-for="order_no"
                label-size="sm"
                class="required"
            >
              <b-form-input
                  v-model="pi.invoiceCode"
                  name="orderNo"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票号码:"
                label-for="order_no"
                label-size="sm"
                class="required"
            >
              <b-form-input
                  v-model="pi.invoiceNo"
                  name="orderNo"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票类型"
                label-for="invoice_type"
                label-size="sm"
                class="required"
            >
              <v-select
                  :options="getCodeOptions('invoice_type')"
                  v-model="pi.invoiceType"
                  name="invoice_type"
                  class="select-size-sm"
                  placeholder="请选择发票类型"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票形式"
                label-for="invoice_material"
                label-size="sm"
                class="required"
            >
              <v-select
                  :options="getCodeOptions('invoice_material')"
                  v-model="pi.invoiceMaterial"
                  name="invoice_material"
                  class="select-size-sm"
                  placeholder="请选择发票类型"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" cols="12" class="mb-2">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="开票日期"
                label-for="invoice_material"
                label-size="sm"
                class="required"
            >
              <flat-pickr
                  v-model="pi.addTime"
                  class="form-control"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票金额:"
                label-for="price"
                label-size="sm"
            >
              <b-form-input
                  v-model="allInvoicePrice"
                  name="price"
                  class="d-inline-block mr-1"
                  size="sm"
                  readonly
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-table
            ref="addRefListTable"
            class="position-relative"
            :items="checked"
            responsive
            hover
            small
            :fields="addModalFields"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            v-model="addTable"
        >

          <!-- Column: Type -->
          <template #cell(in_no)="data">
            [{{ data.item.in_id }}]{{ data.item.in_no }}
          </template>

          <template #cell(returnqty)="data">
            {{ data.item.ext.returnQty }}
          </template>

          <template #cell(tax_rate)="data">
            {{ data.item.ext.rate }}
          </template>

          <template #cell(delivery_amount)="data">
            {{ data.item.ext.deliveryAmount }}
          </template>

          <template #cell(is_invoice_qty)="data">
            {{ data.item.ext.isInvoiceQty }}
          </template>

          <template #cell(no_invoice_qty)="data">
            {{ data.item.ext.noInvoiceQty }}
          </template>

          <template #cell(now_invoice_qty)="data">
            <b-form-input
                v-model="data.item.ext.nowInvoiceQty"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
                type="number"
            />
          </template>

          <template #cell(invoice_price)="data">
            {{ (data.item.ext.nowInvoiceQty*data.item.product_cost_tax).toFixed(2) }}
          </template>
        </b-table>
      </b-card>
    </b-modal>

    <b-modal
        id="modal-remark"
        ok-title="关闭"
        ok-only
        centered
        size="xl"
        title="发票清单"
        ref="purchaseInvoiceModal"
    >
      <b-card
          no-body
          class="mb-0"
      >
        <b-table
            ref="purchaseInvoiceRefListTable"
            class="position-relative"
            :items="purchaseInvoiceItems"
            responsive
            hover
            small
            :fields="purchaseInvoiceModalFields"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(id)="data">
            [{{ data.item.invoice_id }}]
          </template>

          <template #cell(invoice_type)="data">
            {{ getCodeLabel('invoice_type', data.item.invoice_type) }}
          </template>

          <template #cell(create_time)="data">
            {{ toDate(data.item.create_time) }}
          </template>

          <template #cell(creator)="data">
            {{ getCodeLabel('user', data.item.creator) }}
          </template>

          <template #cell(make_date_str)="data">
            {{ data.item.make_date_str }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="editModal(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>

              <b-dropdown-item @click="delPI(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>
      </b-card>
    </b-modal>


  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import purchaseinboundinvoiceUseList from './purchaseinboundinvoiceUseList'
import purchaseinboundinvoiceStore from './purchaseinboundinvoiceStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import XyCheckbox from "@/views/components/xy/XyCheckbox";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    AttachmentUpload,
    XyCheckbox,
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      advanced_search_modal: false,
      table: [],
      user: {},
      checked: [],
      allChecked: false,
      indeterminate: false,
      isCheckedObj: {},
      pi: {},
      addTable: [],
      addModalFields: [
        {key: 'in_no', label: '编号', sortable: true},
        {key: 'order_no', label: '采购订单号', sortable: true},
        {key: 'name', label: '商品名称', sortable: true},
        {key: 'trueqty', label: '入库数量', sortable: true},
        {key: 'returnqty', label: '退货数量'},
        {key: 'product_cost_tax', label: '含税单价', sortable: true},
        {key: 'tax_rate', label: '税率', sortable: true},
        {key: 'delivery_amount', label: '入库金额', sortable: true},
        {key: 'is_invoice_qty', label: '已开票数量', sortable: true},
        {key: 'no_invoice_qty', label: '未开票数量', sortable: true},
        {key: 'now_invoice_qty', label: '本次开票数量', sortable: true},
        {key: 'invoice_price', label: '开票金额', sortable: true},
      ],
      allInvoicePrice: 0,
      show: true,
      purchaseInvoiceModalFields: [
        {key: 'id', label: 'ID'},
        {key: 'invoice_code', label: '发票代码'},
        {key: 'invoice_no', label: '发票号码'},
        {key: 'invoice_type', label: '发票类型'},
        {key: 'make_date_str', label: '开票日期'},
        {key: 'amount', label: '发票金额'},
        {key: 'create_time', label: '创建日期'},
        {key: 'creator', label: '创建人'},
        {key: 'actions', label: '操作'},
      ],
      purchaseInvoiceItems: [],
      saveOrEdit: '',
      advanced_search_modal_excel: false,
      excelSqlId:0,
    }
  },
  computed: {},
  watch: {
    addTable: {
      handler(newVal, oldVal) {
        let price = 0;
        for (let i = 0; i < newVal.length; i++) {
          price = price + parseFloat(isEmpty(newVal[i].ext.nowInvoiceQty) ? 0 : newVal[i].ext.nowInvoiceQty * newVal[i].product_cost_tax)
        }
        this.allInvoicePrice = price.toFixed(2)
        this.$forceUpdate()
      },
      deep: true

    },
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('purchaseinboundinvoice/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.reload();
    },
    resetCondition() {
      this.condition = {}
      this.reload()
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseinboundinvoice')) store.registerModule('purchaseinboundinvoice', purchaseinboundinvoiceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinboundinvoice')) store.unregisterModule('purchaseinboundinvoice')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const create = function () {
      if (isEmpty(this.pi.invoiceCode)) {
        toast.error("请填写发票代码！")
        return false
      }
      if (isEmpty(this.pi.invoiceNo)) {
        toast.error("请填写发票号码！")
        return false
      }
      if (isEmpty(this.pi.invoiceType)) {
        toast.error("请填写发票类型！")
        return false
      }
      if (isEmpty(this.pi.invoiceMaterial)) {
        toast.error("请填写发票形式！")
        return false
      }
      if (isEmpty(this.pi.addTime)) {
        toast.error("请填写开票日期！")
        return false
      }
      const inIdArray = []
      const qtyArray = []
      const itemIdArray = []
      const cRegExp = /^[1-9]\d*$/
      for (let i = 0; i < this.addTable.length; i++) {
        if (!cRegExp.test(this.addTable[i].ext.nowInvoiceQty)) {
          toast.error('开票数量必须是正整数!')
          return false
        }
        inIdArray.push(this.addTable[i].in_id)
        qtyArray.push(this.addTable[i].ext.nowInvoiceQty)
        itemIdArray.push(this.addTable[i].ext.itemId)
      }
      if (this.saveOrEdit === 'save') {
        store.dispatch('purchaseinboundinvoice/createPurchaseInvoice', {
          invoiceCode: this.pi.invoiceCode,
          invoiceNo: this.pi.invoiceNo,
          addTime: this.pi.addTime,
          invoiceType: this.pi.invoiceType.value,
          invoiceMaterial: this.pi.invoiceMaterial.value,
          inIds: inIdArray.join(','),
          qty: qtyArray.join(',')
        }).then(res => {
          if (res.data.code == 0) {
            toast.success("保存成功！")
            this.reload()
          } else {
            toast.error(res.data.data)
            this.reload()
          }
        })
      }
      if (this.saveOrEdit === 'edit') {
        store.dispatch('purchaseinboundinvoice/editPurchaseInvoice', {
          invoiceCode: this.pi.invoiceCode,
          invoiceNo: this.pi.invoiceNo,
          addTime: this.pi.addTime,
          invoiceType: this.pi.invoiceType.value,
          invoiceMaterial: this.pi.invoiceMaterial.value,
          inIds: inIdArray.join(','),
          itemIds: itemIdArray.join(','),
          qty: qtyArray.join(','),
          invoiceId: this.pi.invoiceId
        }).then(res => {
          if (res.data.code == 0) {
            toast.success("保存成功！")
            this.reload()
          } else {
            toast.error(res.data.data)
            this.reload()
          }
        })
      }
    }

    const showAddModal = function () {
      if (this.checked.length === 0) {
        toast.error("请选择单据!")
        return false
      }
      const set = new Set()
      const set1 = new Set()
      for (let i = 0; i < this.checked.length; i++) {
        set.add(this.checked[i].supplier_id)
        set1.add(this.checked[i].company_id)
        if (this.checked[i].ext.noInvoiceQty <= 0) {
          toast.error("请选择未开票数量大于0的数据!")
          return false
        }
      }
      if (set.size != 1) {
        toast.error("请选择相同供应商的数据!")
        return false
      }
      if (set1.size != 1) {
        toast.error("请选择相同采购主体的数据!")
        return false
      }
      this.saveOrEdit = 'save'
      this.$refs['addModal'].show()
    }

    const editModal = function (params) {
      store.dispatch('purchaseinboundinvoice/searchPurchaseInboundInvoiceByInvoiceId', {
        invoiceId: params.invoice_id
      }).then(res => {
        if (res.data.code == 0) {
          this.checked = res.data.data
          this.pi.invoiceCode = params.invoice_code
          this.pi.invoiceNo = params.invoice_no
          this.pi.addTime = toDate(params.make_date_val)
          this.pi.invoiceType = getCode('invoice_type', params.invoice_type)
          this.pi.invoiceMaterial = getCode('invoice_material', params.invoice_material)
          this.pi.invoiceId = params.invoice_id
          this.saveOrEdit = 'edit'
          this.$refs['addModal'].show()
          this.$refs['purchaseInvoiceModal'].hide()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const showPurchaseInvoice = function (params) {
      store.dispatch('purchaseinboundinvoice/searchPurchaseInvoice', {
        ids: params.ext.invoiceIds
      }).then(res => {
        if (res.data.code == 0) {
          this.purchaseInvoiceItems = res.data.data
          this.$refs['purchaseInvoiceModal'].show()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked = []
      this.indeterminate = false
      this.allchecked = false
      this.pi = {}
      refetchData()
    }

    const delPII = function (params) {
      if (confirm('是否删除?')){
        store.dispatch('purchaseinboundinvoice/delPII', {
          inId: params.in_id
        }).then(res => {
          if (res.data.code == 0) {
            toast.success("删除成功!")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }

    }

    const delPI = function (params) {
      if (confirm('是否删除?')){
        store.dispatch('purchaseinboundinvoice/delPI', {
          invoiceId: params.invoice_id
        }).then(res => {
          if (res.data.code == 0) {
            toast.success("删除成功!")
            this.$refs['purchaseInvoiceModal'].hide()
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const exportExcel = function () {
      axios({
            url: '/api/exportexcelfile/purchaseInboundInvoiceExport',
            method:'get',
            params: {
              inNo:condition.value.inNo,
              orderNo:condition.value.poNo,
              product:condition.value.product,
              invoiceCode:condition.value.invoiceCode,
              invoiceNo:condition.value.invoiceNo,
              purchaseTime:condition.value.purchaseTime,
              supplierId:condition.value.supplierId,
              invoicingStatus:isEmpty(condition.value.invoicingStatus)?null:condition.value.invoicingStatus.value,
              startPrice:condition.value.startPrice,
              endPrice:condition.value.endPrice,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "采购发票-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const searchByConditionExcel = function () {
      this.show = false;

      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      const params = {
        excelSqlId: this.excelSqlId,
      }

      store.dispatch('purchaseinboundinvoice/importExcel', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              refetchData()
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = purchaseinboundinvoiceUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,

      advanced_search,
      fromChildren,
      create,
      showAddModal,
      showPurchaseInvoice,
      reload,
      editModal,
      delPI,
      delPII,
      exportExcel,
      advanced_search_excel,
      onUploaded,
      searchByConditionExcel,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
