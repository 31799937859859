import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseinboundinvoiceUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'in_no', label: '编号', sortable: true },
    // { key: 'order_id', label: '采购订单Id', sortable: true },
    { key: 'order_no', label: '采购订单号', sortable: true },
    // { key: 'orderitem_id', label: '采购订单明细 purchase_order_item.item_id', sortable: true },
    // { key: 'inbound_id', label: '入库单Id purchase_inbound.id', sortable: true },
    // { key: 'inbounditem_id', label: '入库单明细 purchase_inbound_item.inbounditem_id', sortable: true },
    // { key: 'inbound_no', label: '入库单编号 purchase_inbound.inbound_no', sortable: true },
    { key: 'purchase_time', label: '采购日期', sortable: true },
    // { key: 'supplier_id', label: '供应商ID purchase_order.supplier_id', sortable: true },
    { key: 'supplier_name', label: '供应商名称', sortable: true },
    // { key: 'company_id', label: '签约主体ID purchase_order.company_id', sortable: true },
    { key: 'company_name', label: '采购组织', sortable: true },
    // { key: 'product_id', label: '入库商品ID purchase_inbound_item.product_id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'trueqty', label: '入库数量', sortable: true },
    { key: 'returnqty', label: '退货数量'},
    { key: 'product_cost_tax', label: '含税单价', sortable: true },
    { key: 'tax_rate', label: '税率', sortable: true },
    { key: 'delivery_amount', label: '入库金额', sortable: true },
    { key: 'no_invoice_qty', label: '未开票数量'},
    { key: 'invoice_code', label: '发票代码'},
    { key: 'invoice_no', label: '发票号码'},
    { key: 'invoicing_status', label: '开票状态', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('in_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('purchaseinboundinvoice/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        inNo:condition.value.inNo,
        orderNo:condition.value.poNo,
        product:condition.value.product,
        invoiceCode:condition.value.invoiceCode,
        invoiceNo:condition.value.invoiceNo,
        purchaseTime:condition.value.purchaseTime,
        supplierId:condition.value.supplierId,
        invoicingStatus:isEmpty(condition.value.invoicingStatus)?null:condition.value.invoicingStatus.value,
        startPrice:condition.value.startPrice,
        endPrice:condition.value.endPrice,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '采购发票列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
